import React from 'react'

export default ({ ...props }) => (
  <svg
    width='23'
    height='19'
    viewBox='0 0 23 19'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2 2C2.37154 2.41181 2.72602 2.60883 3.13131 2.89041C3.84605 3.38698 4.55141 3.998 5.19865 4.71119C5.88879 5.47164 6.60719 6.06155 7.25926 6.90297C8.40096 8.3762 9.54489 9.80687 10.7811 11.0354C11.7056 11.9541 12.67 12.6301 13.6599 13.3014C15.1646 14.3216 16.7281 15.0189 18.229 16.0525C18.7941 16.4417 19.426 16.6757 20 17'
      stroke='black'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M2 17C3.1693 16.3188 4.22336 15.0999 5.3037 14.173C6.30381 13.3149 7.38827 12.6529 8.36296 11.7363C9.38359 10.7765 10.4658 10.141 11.6148 9.55274C12.7392 8.97717 13.7143 8.16883 14.7556 7.35865C15.4206 6.84121 15.9668 6.37427 16.563 5.70253C17.4149 4.74262 18.3331 3.78082 19.2815 3.02321C19.8019 2.60749 20.3896 2 21 2'
      stroke='black'
      strokeWidth='3'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
