import React from 'react'

export default ({ ...props }) => (
  <svg
    width='10'
    height='9'
    viewBox='0 0 10 9'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M2.61325 2.47334C3.24155 1.81363 3.69245 1.68465 4.38062 1.6608C5.56368 1.48485 7.24104 1.48139 7.82619 2.39163C8.24512 3.04331 8.19861 4.54599 7.98143 5.25956C7.67423 6.26896 6.77787 7.03191 5.82436 7.39212C4.24554 7.98857 0.772221 7.19506 1.01179 5.03895C1.13066 3.96915 1.90736 3.21453 2.61325 2.47334Z'
      fill='white'
    />
    <path
      d='M5.12167 1.65626C3.96105 1.65626 3.44116 1.60404 2.61325 2.47334C1.90736 3.21453 1.13066 3.96915 1.01179 5.03895C0.772221 7.19506 4.24554 7.98857 5.82436 7.39212C6.77787 7.03191 7.67423 6.26896 7.98143 5.25956C8.19861 4.54599 8.24512 3.04331 7.82619 2.39163C7.10395 1.26815 4.71782 1.53662 3.65094 1.80334'
      stroke='#FFF4CC'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
