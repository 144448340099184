import React from 'react'

export default ({ ...props }) => (
  <svg
    width='30'
    height='80'
    viewBox='0 0 30 80'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M28.9275 66.9404C29.1048 71.7292 26.7991 75.0103 23.0745 77.4047C20.5915 79.001 17.8424 79.7104 15.0046 79.9765C14.2065 80.0652 13.3196 79.8878 12.5215 79.7104C8.08748 78.9123 4.62893 76.6953 2.41191 72.882C0.726972 69.8669 0.106206 66.5857 0.638291 63.1271C0.993014 60.9988 1.61378 58.8704 3.03267 57.1855C4.89497 55.0572 7.11199 53.3722 10.0385 52.6628C12.7876 51.9533 15.448 52.5741 18.1084 52.7515C19.7933 52.9288 21.3009 53.993 22.7198 54.8798C25.4689 56.5647 27.2425 59.1365 28.3067 62.1516C28.7501 63.5705 28.6614 65.0781 28.8388 66.5857C28.9275 66.763 28.9275 66.9404 28.9275 66.9404ZM14.2065 54.259C14.2065 55.2345 14.2065 56.0327 14.2065 56.8308C14.1178 58.7818 13.9404 60.7327 13.8517 62.7724C13.763 64.4573 13.8517 66.0536 13.8517 67.7385C13.8517 67.9159 14.0291 68.3593 14.2065 68.3593C15.5367 68.5366 16.7782 68.5366 18.1084 68.714C18.9065 68.8027 19.7933 68.9801 20.5915 69.1574C22.5424 69.6895 24.2274 70.7537 25.735 72.1726C25.8236 72.0839 25.9123 71.9952 26.001 71.9065C26.0897 71.7292 26.0897 71.5518 26.1784 71.4631C27.4199 68.9801 27.5973 66.4083 26.8878 63.7479C26.7991 63.3932 26.7104 63.0384 26.4444 62.6837C24.7595 60.112 23.0745 57.4515 20.2367 55.8553C18.3744 54.8798 16.4235 54.6138 14.2065 54.259ZM11.9008 54.4364C10.3045 54.4364 7.9988 55.1459 7.02331 56.0327C6.40254 56.6534 5.78178 57.3629 5.16101 57.9836C3.03267 60.378 2.05718 63.2158 2.41191 66.3196C2.76663 68.9801 3.74212 71.4631 5.33837 73.6801C6.66858 75.5424 8.70824 76.3406 10.9253 76.784C11.0139 76.4292 11.1026 76.2519 11.1913 75.9858C11.1913 75.8085 11.1913 75.5424 11.1913 75.3651C11.28 72.7933 11.28 70.3103 11.3687 67.7385C11.4573 66.5857 11.7234 65.4328 11.7234 64.28C11.8121 61.4422 11.8121 58.6044 11.9008 55.8553C11.9008 55.4119 11.9008 54.9685 11.9008 54.4364Z'
      fill='#080808'
    />
    <path
      d='M13.7636 0.0750009C15.9806 -0.102361 18.0203 -0.0136793 20.06 0.873129C23.4298 2.29202 26.0016 4.59772 27.7752 7.79023C29.1054 10.2733 29.2828 13.0224 29.0167 15.7715C28.5733 21.3584 24.8487 24.3735 20.06 26.4132C18.0203 27.3 15.9806 27.566 13.8523 27.3887C8.08805 26.8566 3.92005 24.1075 1.70303 18.7866C0.727543 16.4809 0.195459 14.0866 0.461501 11.6035C0.727544 9.47516 1.17095 7.4355 2.41248 5.66189C3.92005 3.53355 5.87103 1.75993 8.35409 0.784443C10.2164 0.0749971 11.99 -0.102362 13.7636 0.0750009ZM26.2676 18.7866C26.9771 16.8357 27.5978 14.9734 27.2431 12.7563C26.8884 10.8054 26.2676 9.03176 24.9374 7.61287C24.5827 7.25814 24.228 6.81474 23.9619 6.46001C22.0996 4.24299 19.7052 2.64674 16.8674 2.20334C15.8033 2.02598 14.6504 2.02598 13.4976 1.9373C13.8523 6.72606 13.4089 11.4261 14.207 15.8602C16.424 15.9489 18.5524 15.9489 20.592 16.2149C22.6317 16.3036 24.4053 17.5451 26.2676 18.7866ZM11.99 24.5509C11.1032 17.1017 11.99 9.65253 10.9258 2.20334C10.4824 2.29202 10.039 2.3807 9.59562 2.55806C8.35409 3.00146 7.02388 3.35619 6.22575 4.42036C5.69367 5.04113 5.16158 5.66189 4.71818 6.37134C2.7672 9.03176 2.05776 11.9582 2.7672 15.1507C3.21061 17.1904 4.27478 18.964 5.25026 20.8263C6.58048 23.3094 8.97486 24.1962 11.99 24.5509Z'
      fill='#080808'
    />
  </svg>
)
