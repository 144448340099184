import React from 'react'

export default ({ ...props }) => (
  <svg
    width='31'
    height='31'
    viewBox='0 0 31 31'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M0 15.2824C0 22.8381 5.48757 29.121 12.6646 30.3951V19.4186H8.86522V15.1975H12.6646V11.8199C12.6646 8.02049 15.1127 5.91057 18.5752 5.91057C19.6719 5.91057 20.8548 6.07901 21.9516 6.24745V10.1317H20.0101C18.1522 10.1317 17.7304 11.06 17.7304 12.2429V15.1975H21.7831L21.1081 19.4186H17.7304V30.3951C24.9075 29.121 30.3951 22.8393 30.3951 15.2824C30.3951 6.87688 23.5562 0 15.1975 0C6.83889 0 0 6.87688 0 15.2824Z'
      fill='black'
    />
  </svg>
)
