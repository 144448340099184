import React from 'react'

export default ({ ...props }) => (
  <svg
    width='37'
    height='91'
    viewBox='0 0 37 91'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M30 9.795L30 5.07L34.06 5.07L34.06 0.73L9 0.729999L9 5.07L14.95 5.07L14.95 9.34C14.95 14.485 18.73 17.355 22.72 17.355C26.43 17.355 30 14.94 30 9.795ZM18.905 5.07L26.01 5.07L26.01 9.41C26.01 11.79 24.47 13.015 22.615 13.015C20.62 13.015 18.905 11.72 18.905 9.165L18.905 5.07ZM26.78 33.2037L18.135 33.2037C15.09 33.2037 13.235 31.2787 13.235 28.7937C13.235 26.5537 14.67 25.0487 17.155 25.0487L26.78 25.0487L26.78 20.8487L16.385 20.8487C11.765 20.8487 9.455 23.8937 9.455 27.5687C9.455 30.1587 10.61 32.2237 12.57 33.2037L9.35 33.2037C6.235 33.2037 4.59 31.2087 4.59 28.4787C4.59 26.0637 5.78 24.1737 8.3 23.4037L6.62 19.6587C2.735 20.9887 0.774998 24.2787 0.774998 28.3737C0.774998 33.3087 3.96 37.4037 9.84 37.4037L26.78 37.4037L26.78 33.2037ZM33.5 42.1476L9 42.1476L9 46.3826L16.735 46.3826L9 54.4676L9 60.5576L18.45 50.5476L26.78 59.4376L26.78 53.6626L19.64 46.3826L33.5 46.3826L33.5 42.1476ZM34.935 65.5522C34.935 63.9072 33.745 62.6822 32.17 62.6822C30.63 62.6822 29.44 63.9072 29.44 65.5522C29.44 67.2322 30.63 68.4222 32.17 68.4222C33.745 68.4222 34.935 67.2322 34.935 65.5522ZM1.755 58.0972C1.125 59.4622 0.774997 60.7222 0.774997 62.0172C0.774997 65.2722 2.98 67.6872 6.935 67.6872L26.78 67.6872L26.78 63.4872L7.005 63.4872C5.465 63.4872 4.59 62.5422 4.59 61.2822C4.59 60.7222 4.73 60.0572 5.15 59.3572L1.755 58.0972ZM27.2 80.9259C27.2 75.6409 23.28 71.7909 17.995 71.7909C12.71 71.7909 8.79 75.6409 8.79 80.9259C8.79 86.2109 12.71 90.0609 17.995 90.0609C23.28 90.0609 27.2 86.2109 27.2 80.9259ZM17.995 76.0259C21.18 76.0259 23.42 77.9859 23.42 80.9259C23.42 83.9009 21.18 85.8609 17.995 85.8609C14.81 85.8609 12.605 83.9009 12.605 80.9259C12.605 77.9859 14.81 76.0259 17.995 76.0259ZM33.325 77.3209L30.035 78.3709L32.8 88.1009L36.475 87.0509L33.325 77.3209Z'
      fill='#FFF4CC'
    />
  </svg>
)
