import React from 'react'

export default ({ ...props }) => (
  <svg
    width='313'
    height='112'
    viewBox='0 0 313 112'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M51.8446 111.244C33.1755 111.935 20.3836 102.946 11.0491 88.4259C4.82602 78.7456 2.06022 68.0282 1.02305 56.965C0.677325 53.8535 1.36877 50.3962 2.06022 47.2847C5.17175 29.9985 13.8149 16.5152 28.681 7.87207C40.4357 1.3033 53.2275 -1.11677 66.7108 0.95758C75.0081 2.34048 83.3055 4.76055 89.8743 10.2921C98.1717 17.5524 104.74 26.1955 107.506 37.6044C110.272 48.3219 107.852 58.6936 107.161 69.0653C106.469 75.6341 102.32 81.5114 98.8632 87.043C92.2944 97.7605 82.2684 104.675 70.5137 108.824C64.9821 110.552 59.1048 110.207 53.2275 110.898C52.536 111.244 51.8446 111.244 51.8446 111.244ZM101.283 53.8535C97.4803 53.8535 94.3687 53.8535 91.2572 53.8535C83.6513 53.5077 76.0453 52.8163 68.0937 52.4706C61.5249 52.1248 55.3018 52.4706 48.7331 52.4706C48.0416 52.4706 46.313 53.162 46.313 53.8535C45.6215 59.0393 45.6215 63.8795 44.9301 69.0653C44.5844 72.1769 43.8929 75.6341 43.2015 78.7456C41.1271 86.3516 36.9784 92.9204 31.4468 98.7977C31.7926 99.1434 32.1383 99.4891 32.484 99.8348C33.1755 100.181 33.8669 100.181 34.2126 100.526C43.8929 105.366 53.9189 106.058 64.2907 103.292C65.6736 102.946 67.0565 102.601 68.4394 101.563C78.4654 94.9947 88.8371 88.4259 95.0602 77.3627C98.8632 70.1025 99.9003 62.4966 101.283 53.8535ZM100.592 44.8646C100.592 38.6416 97.826 29.6527 94.3687 25.8498C91.9487 23.4297 89.1829 21.0096 86.7628 18.5895C77.4282 10.2921 66.365 6.48917 54.2647 7.87207C43.8929 9.25497 34.2126 13.0579 25.5695 19.281C18.3093 24.4669 15.1978 32.4185 13.4691 41.0616C14.852 41.4074 15.5435 41.7531 16.5807 42.0988C17.2721 42.0988 18.3093 42.0988 19.0007 42.0988C29.0268 42.4445 38.7071 42.4445 48.7331 42.7903C53.2275 43.136 57.7219 44.1732 62.2163 44.1732C73.2795 44.5189 84.3427 44.5189 95.0602 44.8646C96.7888 44.8646 98.5174 44.8646 100.592 44.8646Z'
      fill='#080808'
    />
    <path
      d='M312.521 52.1248C313.212 60.7679 312.867 68.7196 309.41 76.6712C303.878 89.8088 294.889 99.8348 282.443 106.749C272.763 111.935 262.045 112.627 251.328 111.589C229.547 109.861 217.792 95.3404 209.841 76.6712C206.384 68.7196 205.346 60.7679 206.038 52.4705C208.112 29.9984 218.83 13.7493 239.573 5.10623C248.562 1.30325 257.897 -0.771094 267.577 0.26608C275.874 1.30325 283.826 3.03188 290.74 7.87202C299.038 13.7493 305.952 21.3553 309.755 31.0356C312.521 38.2958 313.212 45.2103 312.521 52.1248ZM239.573 100.872C247.179 103.638 254.439 106.058 263.082 104.675C270.688 103.292 277.603 100.872 283.134 95.6861C284.517 94.3032 286.246 92.9203 287.629 91.8831C296.272 84.6229 302.495 75.2884 304.224 64.2252C304.915 60.0765 304.915 55.582 305.261 51.0876C286.592 52.4705 268.268 50.7419 250.982 53.8534C250.636 62.4965 250.636 70.7939 249.599 78.7456C249.253 86.6973 244.413 93.6118 239.573 100.872ZM217.101 45.2103C246.142 41.7531 275.183 45.2103 304.224 41.0616C303.878 39.333 303.532 37.6044 302.841 35.8757C301.112 31.0356 299.729 25.8497 295.581 22.7382C293.16 20.6638 290.74 18.5895 287.975 16.8609C277.603 9.25492 266.194 6.48913 253.748 9.25492C245.796 10.9835 238.882 15.1322 231.621 18.9352C221.941 24.1211 218.484 33.4557 217.101 45.2103Z'
      fill='#080808'
    />
  </svg>
)
